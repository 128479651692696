import React, { useCallback, useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { mapEdgesToNodes, filterOutDocsWithoutSlugs, cn } from '../lib/helpers'
// import CompanyTitle from '../components/companyTitle/CompanyTitle'
import CompaniesGrid from '../components/companiesGrid/CompaniesGrid'
import Filter from '../components/filter'
import ToggleView from '../components/toggle-view'
import styles from '../components/company-preview-grid.module.css'
import * as start from './content-hub/start.module.css'
import Icon from '../components/icons'
import filterStyles from '../components/filter.module.css'
import { useWindowSize } from '../hooks/useWindowSize.hook'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

gsap.registerPlugin(ScrollToPlugin)
export const query = graphql`
    query CompaniesPageQuery {
        page: sanityPage(_id: { regex: "/(drafts.|)company/" }) {
            title
            _rawBody
            metaTitle
            metaDescription
            metaKeywords
        }

        settings: sanityCompaniesSettings {
            featuredCompanies {
                title
                _id
                _key
                sortOrder
                investmentDate
                slug {
                    current
                }
                _rawExcerpt
                link
                logo {
                    asset {
                        _id
                    }
                }
                logoColor {
                    asset {
                        _id
                    }
                }
                chooseLogo
                brandHex {
                    hex
                }
                brandGradient {
                    useGradient
                    colors {
                        color {
                            hex
                        }
                        colorPoint
                    }
                    gradientDirection
                }
                mainImage {
                    asset {
                        _id
                        url
                    }
                }
                sectors {
                    _id
                    title
                }
                stage {
                    _id
                    title
                }
                }
        }

        companies: allSanityCompany(filter: {display: {ne: false}}, sort: {fields: [sortOrder, title], order: [DESC, ASC]}) {
            edges {
                node {
                    title
                    _id
                    _key
                    sortOrder
                    investmentDate
                    slug {
                        current
                    }
                    _rawExcerpt
                    link
                    logo {
                        asset {
                            _id
                        }
                    }
                    logoColor {
                        asset {
                            _id
                        }
                    }
                    chooseLogo
                    brandHex {
                        hex
                    }
                    brandGradient {
                        useGradient
                        colors {
                            color {
                                hex
                            }
                            colorPoint
                        }
                        gradientDirection
                    }
                    mainImage {
                        asset {
                            _id
                            url
                        }
                    }
                    sectors {
                        _id
                        title
                    }
                    stage {
                        _id
                        title
                    }
                }
            }
        }

        sectors: allSanitySector(sort: { fields: [title], order: ASC }) {
            edges {
                node {
                    id
                    title
                }
            }
        }

        stages: allSanityStage(sort: { fields: [title], order: ASC }) {
            edges {
                node {
                    id
                    title
                }
            }
        }
    }
`

const CompaniesPage = props => {
  const { data, errors } = props

  const { width } = useWindowSize()
  const [isListView, setIsListView] = useState(false)
  const [calcToRender, setCalcToRender] = useState(width <= 1024 ? 12 : (isListView ? 17 : 15))
  const [companiesToRender, setCompaniesToRender] = useState(width <= 1024 ? 12 : (isListView ? 17 : 15))
  const [filtering, setFiltering] = useState({ stage: 'all', sectors: 'all' })
  const [searchQuery, setSearchQuery] = useState('')
  const [openFilter, setOpenFilter] = useState(null)
  const [isSticky, setIsSticky] = useState(false)

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const handleLoadMore = useCallback(() => {
    setCompaniesToRender(companiesToRender + calcToRender)
  }, [companiesToRender])

  const sectorsFilterRef = useRef(null)
  const stagesFilterRef = useRef(null)

  const page = data.page
  const sectorNodes = (data || {}).sectors
    ? mapEdgesToNodes(data.sectors)
    : []
  const stageNodes = (data || {}).stages
    ? mapEdgesToNodes(data.stages)
    : []

  const rawCompanyNodes =
    data && data.companies &&
    mapEdgesToNodes(data.companies)
      .filter(filterOutDocsWithoutSlugs)
      .filter(node => !node._id.startsWith('drafts.'))

  const featuredCompanies = data?.settings?.featuredCompanies || []

  const getCompanyNodes = () => {
    if (isListView) {
      // return rawCompanyNodes.sort((a, b) => {
      //   const sortOrderA = a.sortOrder === 0 ? null : a.sortOrder
      //   const sortOrderB = b.sortOrder === 0 ? null : b.sortOrder
      //
      //   if (sortOrderA !== null && sortOrderB !== null) {
      //     return sortOrderB - sortOrderA
      //   }
      //
      //   if (sortOrderA === sortOrderB) {
      //     return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      //   }
      //
      //   return sortOrderA === null ? 1 : -1
      // })

      return [...rawCompanyNodes].sort((a, b) =>
        a.title.toLowerCase().localeCompare(b.title.toLowerCase())
      )
    } else {
      let companies = [...featuredCompanies]

      // if (companies.length < companiesToRender) {
      //   const remainingCompanies = rawCompanyNodes.filter(
      //     company => !featuredCompanies.some(fc => fc._id === company._id)
      //   )
      //
      //   const needed = companiesToRender - companies.length
      //   companies = [...companies, ...remainingCompanies.slice(0, needed)]
      // }

      return companies
    }
  }

  const companyNodes = getCompanyNodes()

  const handleFilter = (filter) => {
    setFiltering(prevFilters => {
      const newFilters = {
        ...prevFilters,
        [filter[0]]: filter[1]
      }

      if (newFilters[filter[0]] !== prevFilters[filter[0]]) {
        gsap.to(window, { duration: 1, scrollTo: { y: '#companies-section', offsetY: 333 }, ease: 'power4.inOut' })
      }

      return newFilters
    })

    setCompaniesToRender(width <= 1024 ? 12 : (isListView ? 17 : 15))
  }

  const handleSearch = (event) => {
    setSearchQuery(event.target.value.toLowerCase())
    setCompaniesToRender(calcToRender)
    gsap.to(window, { duration: 1, scrollTo: { y: '#companies-section', offsetY: 333 }, ease: 'power4.inOut' })
  }

  const resetFilters = () => {
    setFiltering({ stage: 'all', sectors: 'all' })
    if (sectorsFilterRef.current) sectorsFilterRef.current.reset()
    if (stagesFilterRef.current) stagesFilterRef.current.reset()
  }

  const handleOpenFilter = (filter) => {
    setOpenFilter(prevFilter => (prevFilter === filter ? null : filter))
  }

  const filteredCompanies = companyNodes.filter(company => {
    const stageMatch = filtering.stage === 'all' || company.stage.some(stage => stage.title === filtering.stage)
    const sectorMatch = filtering.sectors === 'all' || company.sectors.some(sector => sector.title === filtering.sectors)
    const searchMatch = company.title.toLowerCase().includes(searchQuery)
    return stageMatch && sectorMatch && searchMatch
  })

  const handleLoadAll = useCallback(() => {
    setCompaniesToRender(companiesToRender + filteredCompanies.length)
  }, [companiesToRender])

  useEffect(() => {
    const newValue = width <= 1024 ? 12 : (isListView ? 17 : 15)
    setCalcToRender(newValue)
    setCompaniesToRender(newValue)
  }, [isListView])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openFilter && !event.target.closest(`.${filterStyles.filter}`)) {
        setOpenFilter(null)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openFilter])

  useEffect(() => {
    const sentinel = document.querySelector('.sentinel')

    const observerOptions = {
      root: null,
      threshold: [1]
    }

    // eslint-disable-next-line no-undef
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio < 1) {
          setIsSticky(true)
        } else {
          setIsSticky(false)
        }
      })
    }, observerOptions)

    observer.observe(sentinel)

    return () => {
      observer.disconnect()
    }
  }, [])

  const handleResponsiveSettings = () => {
    if (width <= 1024) {
      setCalcToRender(12)
      setCompaniesToRender(12)
    } else {
      setCalcToRender((isListView ? 17 : 15))
      setCompaniesToRender((isListView ? 17 : 15))
    }
  }

  const switchToListView = () => {
    resetFilters()
    setIsListView(true)

    gsap.to(window, { duration: 1, scrollTo: { y: '#companies-section', offsetY: 333 }, ease: 'power4.inOut' })
  }

  useEffect(() => {
    handleResponsiveSettings()
  }, [width])

  return (
    <Layout page='companies' hideContactBar>
      <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} />

      {/* <CompanyTitle smallText='WHERE THE' bigText='Greats rise' /> */}
      <div className={styles.companiesWrapper}>
        <div className={cn('sentinel', isSticky && styles.sticky)} />
        <section className={cn(styles.filteringSearch, isSticky && styles.sticky, isListView ? styles.borderBottom : null)}>
          <h2>{page.title}</h2>
          <div className={styles.filtringWrapper}>
            <ToggleView isListView={isListView} setIsListView={setIsListView} resetFilters={() => { resetFilters(); handleResponsiveSettings() }} />
            {isListView && (
              <>
                <Filter
                  ref={sectorsFilterRef}
                  title='Sectors'
                  options={sectorNodes}
                  filter='sectors'
                  filterHandler={handleFilter}
                  isOpen={openFilter === 'sectors'}
                  handleOpenFilter={state => handleOpenFilter(state)}
                />
                <Filter
                  ref={stagesFilterRef}
                  title='Stage'
                  options={stageNodes}
                  filter='stage'
                  filterHandler={handleFilter}
                  isOpen={openFilter === 'stage'}
                  handleOpenFilter={state => handleOpenFilter(state)}
                />
                <div className={styles.websiteText}>Website</div>
              </>
            )}
            <div className={`${start.searchBoxWhite} ${styles.search}`}>
              <form onSubmit={(e) => e.preventDefault()}>
                <Icon symbol='search' />
                <input
                  type='text'
                  placeholder='Search'
                  value={searchQuery}
                  onChange={handleSearch}
                />
              </form>
            </div>
          </div>
        </section>

        <CompaniesGrid
          companies={filteredCompanies} companiesToRender={companiesToRender} calcToRender={calcToRender}
          handleLoadMore={handleLoadMore} isListView={isListView} switchToListView={() => switchToListView()} handleLoadAll={handleLoadAll}
        />
      </div>

      {/* {companyNodes && companyNodes.length > 0 && <CompanyGrid nodes={companyNodes} sectors={sectorNodes} stages={stageNodes} body={page._rawBody} />} */}
    </Layout>
  )
}

export default CompaniesPage
